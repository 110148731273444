import React from 'react';

function Contact() {
  return (
    <section className="contact">
      <h2>Contact Us</h2>
      <p>For inquiries or more information, please contact us at info@vap_evlogistics.com.</p>
    </section>
  );
}

export default Contact;