import React from 'react';

function Career() {
  return (
    <section className="career">
      <h2>Career For Life</h2>
      <p>Pre-alert of delivery time to customer and High-level SLAs are achieved...</p>
    </section>
  );
}

export default Career;