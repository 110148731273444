import React from 'react';
import '../Styles/Services.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarehouse, faTruck, faBoxOpen,  } from '@fortawesome/free-solid-svg-icons';
import Lastmiledelivery from '../Resource/Lastmiledelivery.png';


function Services() {
  return (
    <section className="services">
      <h2 className="services-heading">What Services Do We Offer?</h2>
      <div className="services-container">
        <div className="service-box">
          <FontAwesomeIcon icon={faTruck} className="service-icon" />
          <h3>Last Mile Delivery Services</h3>
          <p>
            VAP Logistics and Transportation Services Pvt. Ltd. offers tailored last-mile delivery solutions designed to meet the evolving needs of modern e-commerce and retail.
          </p>
        </div>

        <div className="service-box">
          <FontAwesomeIcon icon={faTruck} className="service-icon" />
          <h3>Middle Mile Services</h3>
          <p>
            By partnering with VAP Logistics, businesses benefit from our focus on safety, timely deliveries, and cost-effective solutions tailored to their specific needs.
          </p>
        </div>

        <div className="service-box">
          <FontAwesomeIcon icon={faTruck} className="service-icon" />
          <h3>Intra and Inter-City Services</h3>
          <p>
            Our intra-city solutions prioritize timely and secure deliveries. For inter-city transportation, we combine speed and reliability to ensure seamless movement of goods.
          </p>
        </div>

        <div className="service-box">
          <FontAwesomeIcon icon={faTruck} className="service-icon" />
          <h3>Manpower Services</h3>
          <p>
            White and Blue Collar Manpower Services, Delivery Captains, EV Vehicle Services, and more.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Services;