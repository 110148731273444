import React from 'react';
import '../Styles/About.css'; // Link to the CSS file
import Vision from "../Resource/Vision_vap.jpeg";
import Mission from "../Resource/Mission_vap.jpeg";
import Leadership from '../components/Leadership';

function About() {
  return (
    <section  className="about">
      {/* First Flex: "Who We Are" */}
      <div className="who-we-are">
        <h2>Who We Are</h2>
        <p>Founded in 2021, VAP Logistics and Transportation Services Pvt. Ltd. has quickly emerged as a leader in the logistics and transportation sector. Our company specializes in delivering a wide array of customized solutions that cater to the diverse requirements of various industries, including corporate, manufacturing, hospitality, retail, infrastructure, and e-commerce.
At the heart of VAP’s philosophy is our steadfast commitment to providing outstanding service. We believe that understanding our clients' unique challenges is essential to fostering strong partnerships. By engaging in meaningful dialogue and actively listening to our clients, we can effectively identify potential risks and develop tailored strategies that not only protect their revenue but also enhance their brand reputation.
Our approach is underpinned by a profound understanding of the complexities within our clients’ businesses. This allows us to craft logistics solutions that are both proactive and adaptable, addressing immediate needs while also anticipating future challenges.</p>
<p> Our experienced team excels in designing and implementing robust logistics and security protocols, ensuring that operations run smoothly and securely.
Moreover, VAP is driven by a culture of innovation and adaptability. We recognize that the logistics landscape is constantly evolving, and we are committed to staying ahead of industry trends and technological advancements. This agility enables us to refine our services continually and offer cutting-edge solutions that keep our clients competitive.
Our seasoned professionals bring a wealth of expertise and insights, supported by an Advisory Board composed of distinguished leaders from law enforcement and military backgrounds. This unique blend of experience and strategic guidance positions us to tackle the most complex logistical challenges with confidence.
VAP Logistics and Transportation Services Pvt. Ltd. stands out for our dedication to exceptional service, deep industry knowledge, and innovative solutions. We strive not just to meet but to surpass our clients’ expectations, making us a trusted partner in their growth and success in a rapidly changing marketplace.</p>
<p> VAP Logistics and Transportation Services Pvt. Ltd. stands out for our dedication to exceptional service, deep industry knowledge, and innovative solutions. We strive not just to meet but to surpass our clients’ expectations, making us a trusted partner in their growth and success in a rapidly changing marketplace.</p>
        
      </div>

      {/* Second Flex: Image on the left, Vision on the right */}
      <div className="vision-mission-flex">
        <div className="image-container vision-image">
          <img src={Vision} alt="Vision" height={'250px'}/>
        </div>
        <div className="content-container vision-content">
          <h3>Our Mission</h3>
          <p>“To revolutionize logistics and manpower services by fostering innovation, empowering communities, and delivering sustainable solutions that transform industries, uplift society, and preserve our planet for future generations.”</p>
        </div>
      </div>
      <div className="vision-mission-flex">
        <div className="image-container vision-image">
        <img src={Mission} alt="Mission"  height={'250px'}/>
        </div>
        <div className="content-container vision-content">
          <h3>Our Vision</h3>
          <p>“At VAP Logistics and Transportation Services Pvt Ltd, we are committed to creating a positive societal impact by blending technology, operational excellence, and environmental responsibility. Through sustainable supply chain practices and inclusive workforce solutions, we aim to drive efficiency, enrich communities, and give back to nature while setting new standards in service delivery across India.”</p>
        </div>
      </div>

      <Leadership/>
    </section>
  );
}

export default About;