
import  './Styles/Footer.css'
import './Styles/bootstrap.min.css'
import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import About from "./components/About";
import Career from "./components/Career";
import Services from './components/Services';
import Facebook from "./Resource/Facebook.png"


class Footer extends Component{
    render(){
        return(
            <Container fluid className="footer mt-5 py-5">
        
          <Row className="row g-5 ms-3">
            <Col lg={3} >
              <h4 className="text-light mb-4">Address</h4>
              <p>
                <i className="fa fa-map-marker-alt me-3"></i>SVV Complex, 1st Floor, Above Bajaj Showroom, Kavalahosahalli, Chandapura Anekal Road, Anekal, Bangalore -562106, INDIA
              </p>
              <p>
                <i className="fa fa-phone-alt me-3"></i>+91 9606993383
              </p>
              <p>
                <i className="fa fa-envelope me-3"></i>info@vap_evlogistics.com
              </p>
            </Col>
            <Col lg={3}>
              <h4 className="text-light mb-4">Our Service</h4>
              <a className="btn btn-link" href="about">About Us</a>
              <a className="btn btn-link" href="career">Contact Us</a>
              <a className="btn btn-link" href="services">Our Services</a>
              <a className="btn btn-link" href="">Terms & Condition</a>
              <a className="btn btn-link" href="">Support</a>
            </Col>
            <Col lg={3}>
            <h4 className="text-light mb-4">Social Media</h4>
              <a className="btn btn-link" href="#about">Facebook  </a>
              <a className="btn btn-link" href="#career">Twitter</a>
              <a className="btn btn-link" href="#services">YouTube</a>
              <a className="btn btn-link" href="#Services">Instagram</a>
            </Col>
            <Col lg={3} >           
        <h4 className="text-light mb-4">Newsletter</h4>
        <p>Dolor amet sit justo amet elitr clita ipsum elitr est.</p>
        <InputGroup className="mx-auto">
        <Form.Control className=''
          placeholder="User's Email"
          aria-label="User's Email"
          aria-describedby="basic-addon2"
        />
        <Button variant="outline-secondary" id="button-addon2">
          Button
        </Button>
      </InputGroup>
     
            </Col>
          </Row>
        
      </Container>
        );
    }
}
export default Footer