import React from 'react';
import '../Styles/Leadership.css'; // Assuming you have a CSS file for styling
import Arunakumara from '../Resource/Arunakumara.jpg';
import SowmyaP from '../Resource/SowmyaP.jpg';
import Vinaykumar from '../Resource/Vinaykumar.jpg';

// Leader data (you can replace with actual data)
const leaders = [
    {
        name: 'Vinay Kumar N',
        position: 'CEO',
        photo: Vinaykumar, // Replace with actual image URL
        description: `Mr. Vinay Kumar N is recognized as one of the most accomplished leaders in retail and logistics. With an MBA in Supply Chain Management and 17 years of experience, he has held key roles at leading companies, including six years at Shell India Markets Pvt. Ltd., and stints at Amazon, Grofers, Busybees, Shippr, Mfine, Wheelocity, and Swiggy Instamart. His vast experience and strategic leadership have been instrumental in driving business development and forming impactful partnerships for the company.`,
      },
      {
        name: 'Sowmya P',
        position: 'HR Director',
        photo: SowmyaP, // Replace with actual image URL
        description: `Mrs. Sowmya P has successfully led the Human Resources function at Rajananda Projects and other reputed organizations for the past seven years. Her expertise lies in implementing strategic HR initiatives that align with business objectives, fostering a positive work environment, and enhancing employee engagement, performance, and retention. Mrs. Sowmya’s collaborative approach, strong communication skills, and mentor-ship capabilities have significantly contributed to the growth and success of both the organization and its employees. Her dedication to continuous improvement has made her an invaluable asset to the company.`,
      },
      {
        name: 'Abhinav Gautam',
        position: 'COO',
        photo: 'https://via.placeholder.com/150', // Replace with actual image URL
        description: `Mr. Abhinav Gautam is a dynamic, transformational leader with over 10 years of experience in Amazon’s Last Mile delivery operations. His proven ability to optimize logistics, reduce costs, and elevate customer satisfaction has led to impressive results. Known for his strategic vision, Mr. Abhinav excels at fostering collaboration, leveraging data-driven insights, and surpassing performance targets to inspire teams toward operational excellence and enhanced customer experiences.`,
      },
  {
    name: 'Arunakumara N',
    position: 'CTO',
    photo: Arunakumara, 
    description: `Mr. Arunakumar leads the IT department with over 24 years of experience, driving innovation and aligning technology strategies with business objectives. Formerly an end-to-end product development manager at TVS for 18 years, he has successfully managed large-scale projects and implemented cutting-edge technologies. A master's graduate from Dayananda Sagar University and certified in Oracle DBA, he fosters a culture of collaboration and mentors emerging IT professionals.`,
  },
  
  // Add more leaders as needed
];

function Leadership() {
  return (
    <section className="leadership">
      <h2 className="leadership-heading">Our Leadership</h2>
      <div className="leaders-container">
        {leaders.map((leader, index) => (
          <div className="leader-card" key={index}>
            <img src={leader.photo} alt={leader.name} className="leader-photo" />
            <div className="leader-info">
              <h3 className="leader-name">{leader.name}</h3>
              <p className="leader-position">{leader.position}</p>
              <p className="leader-description">{leader.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Leadership;