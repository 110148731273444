import React from 'react';
import '../Styles/Home.css';  // Import CSS specific to Home component

function Home() {
  return (
    <section className="home">
      <div className="home-content">
        <h1>VAP Logistics and Transportation Service Pvt Ltd</h1>
        <p>We harness cutting-edge technology to streamline supply chains and optimize workforce management. Our mission is to deliver innovative, tech-driven solutions that empower businesses with agility and efficiency across India</p>
      </div>
    </section>
  );
}

export default Home;